/**
 * Swap class
 */

const check = document.querySelector('html')

window.addEventListener('load', function () {
  check.classList.add('theme-brand')
  // console.log('Love!')
})
